import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';

import CustomerList from './customer-list.vue';
import CompanyList from './company_list.vue';
import OrgList from './org_list.vue';
import PositionList from './position-list.vue';

formCreate.component('PositionList', PositionList);
formCreate.component('CustomerList', CustomerList);
formCreate.component('CompanyList', CompanyList);
formCreate.component('OrgList', OrgList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'extra_cost_form',
      formParentCode: 'CRM20210918000002710',
    };
  },

  methods: {

    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'customerName') { // 选择客户
        v.props = {
          ...v.props,
          params: {
            functionCode: 'select_customer',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'orgName') { // 选择组织
        v.props = {
          ...v.props,
          params: {
            functionCode: 'org_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'ext8') { // 选择公司主体
        v.props = {
          ...v.props,
          params: {
            functionCode: 'company_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'ext10') { // 选择职位
        v.props = {
          ...v.props,
          params: {
            functionCode: 'position_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'ext1') {
        v.restful = '/tpm/tpmFiscalYearSettingController/list';
        v.restfulParams = { pageSize: -1, enableStatus: '009' };
        v.props = {
          ...v.props,
          remoteParams: 'yearName',
          remote: true,
          filterable: true,
        };
        v.optionsKey = {
          label: 'yearName',
          value: 'year',
        };
      }
      if (v.field === 'budgetSubjectsCode') { // 根据费用类型，查询关联的预算科目
        v.restful = '/tpm/tpmBudgetSubjectsExt/list';
        v.restfulParams = {
          pageSize: -1,
          subjectsTypeCode: 'add',
          enableStatus: '009',
        };
        v.props = {
          ...v.props,
          remoteParams: 'budgetSubjectsName',
          remote: true,
          filterable: true,
        };
        v.optionsKey = {
          label: 'budgetSubjectsName',
          value: 'budgetSubjectsCode',
        };
        v.on = {
          change: (e) => {
            if (e) {
              this.setValue({
                budgetSubjectsCode: e,
              });
            } else {
              this.setValue({
                budgetSubjectsCode: null,
                budgetSubjectsName: null,
              });
            }
          },
          getLabel: (e) => {
            this.setValue({
              budgetSubjectsName: e,
            });
          },
        };
      }
      return v;
    },

    formComplete() {
      const customerName = this.getRule('customerName'); // 客户
      const companyName = this.getRule('ext8'); // 公司
      const orgName = this.getRule('orgName'); // 组织
      const ext10 = this.getRule('ext10'); // 职位

      this.setValue({
        feeBudgetType: 'add',
        feeBudgetTypeName: '额外费用',
      });
      // 职位
      ext10.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            ext10: e[0].positionName,
            ext9: e[0].positionCode,
          });
        } else {
          this.setValue({
            ext10: null,
            ext9: null,
          });
        }
      };

      // 公司主体
      companyName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            ext8: e[0].companyName,
            ext7: e[0].companyCode,
          });
        } else {
          this.setValue({
            ext8: null,
            ext7: null,
          });
        }
      };

      // 提示：客户和组织互斥，二者只能选其一
      customerName.on.change = (e) => {
        if (e && e.length > 0) {
          this.hiddenFields(true, ['orgName']);
          this.hiddenFields(true, ['ext10']);
          this.setValue({
            customerName: e[0].customerName,
            customerCode: e[0].customerCode,
            orgCode: null,
            orgName: null,
            ext10: null,
            ext9: null,
          });
        } else {
          this.hiddenFields(false, ['orgName']);
          this.hiddenFields(false, ['ext10']);
          this.hiddenFields(false, ['customerName']);
          this.setValue({
            customerName: null,
            customerCode: null,
            orgCode: null,
            orgName: null,
            ext10: null,
            ext9: null,
          });
        }
      };

      // 组织
      orgName.on.change = (e) => {
        if (e && e.length > 0) {
          this.hiddenFields(true, ['customerName']);
          this.hiddenFields(false, ['ext10']);
          this.setValue({
            customerName: null,
            customerCode: null,
            orgCode: e[0].orgCode,
            orgName: e[0].orgName,
          });
        } else {
          this.hiddenFields(false, ['customerName']);
          this.hiddenFields(false, ['orgName']);
          this.hiddenFields(false, ['ext10']);
          this.setValue({
            customerName: null,
            customerCode: null,
            orgCode: null,
            orgName: null,
            ext9: null,
            ext10: null,
          });
        }
      };

      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request.get('/tpm/tpmFeeBudgetController/query', {
          id: this.formConfig.row.id,
          feeBudgetType: 'add',
        }).then((res) => {
          if (res.success) {
            this.setValue(res.result);
            if (orgName.value) { // 组织存在，隐藏客户
              this.hiddenFields(true, ['customerName']);
            }
            if (customerName.value) { // 客户存在，隐藏组织和职位
              this.hiddenFields(true, ['orgName', 'ext10']);
            }
          }
        });
      }
    },

    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        const url = '/tpm/tpmFeeBudgetExt/save';
        // 有id  就是编辑
        if (this.formConfig.row.id) {
          formData.id = this.formConfig.row.id;
        }
        formData.budgetYear = '2021'; // 标品必传字段，写死
        formData.budgetQuater = '1';
        formData.budgetMonth = '01';
        // formData.attachmentList = JSON.stringify(formData.attachmentList || []);
        request.post(url, formData).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
